//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useViewport, ViewportProvider } from 'react-viewport-provider';
import { DataSet } from 'vis-data';
import { BLUE_PRINT } from './config/default-blueprint';
import { ReactFormBuilder } from './core';
import './core/dist/app.css';
import './style.css';
import { HttpServer } from './HttpServer';

const ID = require('./uuid');

export const formDS = new DataSet({
  fieldId: 'key',
});
formDS.update(BLUE_PRINT.toolbar.items);

export interface IHeader {
  key: string;
  value: string;
}

export interface IToolbarItem {
  key: "Header" |
  "Label" |
  "Paragraph" |
  "LineBreak" |
  "Dropdown" |
  "Tags" |
  "Checkboxes" |
  "RadioButtons" |
  "TextInput" |
  "PasswordInput" |
  "NumberInput" |
  "TextArea" |
  "Image" |
  "Rating" |
  "DatePicker" |
  "TextEditor" |
  "Signature" |
  "HyperLink" |
  "Download" |
  "Range" |
  "Camera" |
  "FileUpload";
  name?: string;
  label?: string;
  [x: string]: any;
}

export interface IWidgetProps {
  containerStyle?: any;
  formId?: string;
  label: string;
  access_token: string;
  url: string;
  header?: IHeader[];
  toolbarItems?: 'all' | IToolbarItem[];
}

const http = new HttpServer();

const getUrl = (url: string, cid: string) => {
  return `${url}?cid=${cid}`
};

const FormBuilderVInternal = (props: IWidgetProps) => {
  const [formId, setFormId] = useState<string>(props.label ?? ID.uuid);
  useEffect(() => {
    if (props.header && Array.isArray(props.header) && props.header.length > 0) {
      props.header.forEach((header: IHeader) => {
        http.setHeader(header.key, header.value);
      });
    }
  }, []);
  const { width, height } = useViewport();
  const styles = StyleSheet.create({
    containerStyle: {
      width: 0.6 * width,
      flexDirection: 'column',
      paddingTop: 0.1 * height,
      // alignItems: 'center',
      // justifyContent: 'center',
    }
  })

  const onLoad = async () => {
    const url = getUrl(props.url, formId);
    const res: any = await http.get(url);
    return res?.data?.formsuite?.form?.task_data ?? [];
  };

  const onPost = (data: any) => {
    // const saveUrl = getUrl(props.url, formId);
    http.post(props.url, {
      cid: formId,
      label: formId,
      form: data,
      access_token: props.access_token,
    });
  };
  if (props.toolbarItems) {
    if (props.toolbarItems !== 'all') {
      formDS.clear();
      formDS.update(props.toolbarItems);
    }
  }

  return (
    <View style={StyleSheet.flatten([styles.containerStyle, props.containerStyle])}>
      <ReactFormBuilder
        onPost={onPost}
        onLoad={onLoad}
        toolbarItems={formDS.get()}
      />
    </View>
  )

}

export const FormBuilderV = (props: IWidgetProps) => {
  return (
    <ViewportProvider>
      <FormBuilderVInternal {...props} />
    </ViewportProvider>
  )
}

export const uuidGen = ID;
