import {ConsoleLogger, LoggerService} from 'logger-extension-vfs';
import React, {useState, useEffect} from 'react';
import {Dashboard} from './components';
import {useAuth} from '../../services/auth-service/auth.provider';
import config from '../../config/default.config.json';
import {HttpServer} from '../../servers/HttpServer';

// const console: LoggerService = new LoggerService(
//   new ConsoleLogger('Dashboard:container'),
// );
let REVIEW_PER_PAGE = 12;
const http = new HttpServer();

export const DashboardView = (props: any) => {
  const [userProfile, setUSerProfile] = useState<any>(null);
  const [loader, setLoader] = React.useState<boolean>(true);
  const [tableData, setTableData] = React.useState([]);
  const [token, setToken] = useState<any>();

  const {getUserProfile, getAuthTokenFast} = useAuth();

  React.useEffect(() => {
    const res: any = getAuthTokenFast();
    if (res && res.value) {
      console.log('check result', res?.value);
      setToken(res.value);
    }
  }, []);

  useEffect(() => {
    getUserProfile().then(userProfile => {
      setUSerProfile(userProfile);
    });
  }, []);

  useEffect(() => {
    _getTableData();
  }, [token]);

  const _getTableData = async () => {
    console.log('tokens', token);
    if (token) {
      try {
        loader && setLoader(true);
        let _data = await http.post(config.servers.http.tableEP, {
          skip: 0,
          limit: 5000,
          access_token: token,
        });
        if (_data) {
          console.log(`table data:`, _data);
          setTableData(_data?.data?.forms);
          if (loader) {
            setLoader(false);
          }
        } else {
          if (loader) {
            setLoader(false);
          }
          console.error('table data null');
        }
      } catch (err) {
        if (loader) {
          setLoader(false);
        }
        console.error('Error in getting table Data', err);
      }
    }
  };

  const onSideNavItemSelect = (eventKey: string) => {
    console.log(`eventKey: `, eventKey);
  };

  const _renderBody = () => {
    return (
      <>
        <Dashboard
          userProfile={userProfile}
          sidebarNavItemSelect={onSideNavItemSelect}
          getFormPostURL={config.servers.http.formEP}
          tableData={tableData && tableData}
          loader={loader}
        />
      </>
    );
  };

  return <>{_renderBody()}</>;
};
