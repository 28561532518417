import React from 'react';

import {useViewport} from 'react-viewport-provider';

import {FormPreviewLG} from './FormPreview.lg';
import {FormPreviewMD} from './FormPreview.md';
import {FormPreviewSM} from './FormPreview.sm';


export const FormPreview = (props: any) => {
    const {bp} = useViewport();

    if(bp === 'xs' || bp === 'sm'){
        return <FormPreviewSM {...props} />
    } else if(bp === 'md'){
        return <FormPreviewMD {...props} />
    } else 
    
        return <FormPreviewLG {...props} />
    
}