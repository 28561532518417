import React, {useContext, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {ThemeContext} from '../../../../../../providers/theme';
import {useViewport} from 'react-viewport-provider';
import config from '../../../../config/default-blueprint.json'
import {ButtonV, IconButtonV} from 'react-button-rs';
import {Panel} from 'rsuite';
import { InputV } from 'react-input-rs';
import {v4 as uuidv4} from 'uuid';
import { Input, InputGroup } from 'rsuite';

 const BP = config.GenerateButtonScreen;

type GenerateButtonProps = {
  title?: 'string';
  onConfirmClick?(): void;
  onButtonClick?(): void;
  disabled?: any;
  uuidValue?:any;
  buttonOne?:string;
  buttonTwo?:string;
  titleOne?:string;
  titleTwo?:string;
};

export const GenerateButtonLG = (props: GenerateButtonProps) => {

    const {buttonOne,buttonTwo, titleOne,titleTwo}=props
  const theme = useContext(ThemeContext);
  const {width, height} = useViewport();
  //   const onButtonClick = ()=>{
  //     const _id = uuidv4();
  //     setId(_id)

  //   }
  //   console.log(id)

  const _panelStyle = () => {
    let _style: any = {
        width: width * 0.5
    }
    return _style;
}

  return (
    <View>
      <Panel shaded style={StyleSheet.flatten([styles.panelStyle,_panelStyle()])}>
        <View style={StyleSheet.flatten([styles.titleContainer1])}>
          <Text style={StyleSheet.flatten([styles.titleStyle1])}>
            {titleOne}
          </Text>
        </View>
        <ButtonV
          onPress={props?.onButtonClick}
          buttonTitle={buttonOne}
          color={'red'}
          buttonTitleStyle={{color: '#fff'}}
          containerStyle={StyleSheet.flatten([styles.buttonOneContainer])}
         
        />
        <View style={StyleSheet.flatten([styles.inputContainerStyle])}>
             <div  style={{ width: '40%', marginBottom: 20 }}> 
        <Input
        value={props?.uuidValue}
        style={StyleSheet.flatten([styles.inputFieldStyle])}
        />
        </div>
        </View>
        
        <View style={StyleSheet.flatten([styles.titleContainer2])}>
          <Text> {titleTwo}</Text>
        </View>
        <ButtonV
          onPress={props?.onConfirmClick}
          buttonTitle={buttonTwo}
          appearance={'ghost'}
          color={'green'}
          buttonTitleStyle={{color: '#000000'}}
          containerStyle={StyleSheet.flatten([styles.buttonTwoContainer])}
         
          disabled={props?.disabled}
        />

      </Panel>
    </View>
  );




};
const styles = StyleSheet.create({
    buttonTitleStyle: {fontWeight: 'bold', fontSize: 12},
    
    panelStyle: {
      backgroundColor: '#fff',
      opacity: '0.7',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20% auto',
    },
    titleContainer1: {
      width: '100%',
      // margin: '0 auto',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      marginBottom: '10px',
    },
    titleContainer2: {
      width: '100%',
      margin: '0 auto',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '50px',
      marginBottom: '10px',
    },
    titleStyle1: {
      fontWeight: '600',
    },
    inputFieldStyle:{
        fontWeight:"bold",
        textAlign:"center",
        justifyContent:"center",
        color:"black"
    },
    inputContainerStyle:{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20

    },
    buttonOneContainer:{
        width: '100%',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonTwoContainer:{
        width: '100%',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
    }
  });


  GenerateButtonLG.defaultProps = {
    buttonOne:BP?.button1,
    buttonTwo:BP?.button2,
    titleOne:BP?.title1,
    titleTwo:BP?.title2
  } as Partial<GenerateButtonProps>