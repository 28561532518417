import React from 'react';

import {useViewport} from 'react-viewport-provider';

import {ProfileTemplateLG} from './ProfileTemplate.lg';
import {ProfileTemplateMD} from './ProfileTemplate.md';
import {ProfileTemplateSM} from './ProfileTemplate.sm';

export const ProfileTemplate = (props: any) => {
    const {bp} = useViewport();

    if(bp === 'xs' || bp === 'sm'){
        return <ProfileTemplateSM {...props} />
    } else if(bp === 'md'){
        return <ProfileTemplateMD {...props} />
    } else 
    
        return <ProfileTemplateLG {...props} />
    
}   