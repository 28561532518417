import React from 'react';

import {useViewport} from 'react-viewport-provider';

import {GenerateButtonLG} from './GenerateButton.lg';
import {GenerateButtonMD} from './GenerateButton.md';
import {GenerateButtonSM} from './GenerateButton.sm';


export const GenerateButton = (props: any) => {
    const {bp} = useViewport();

    if(bp === 'xs' || bp === 'sm'){
        return <GenerateButtonSM {...props} />
    } else if(bp === 'md'){
        return <GenerateButtonMD {...props} />
    } else 
    
        return <GenerateButtonLG {...props} />
    
}