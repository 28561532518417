import React, {useState} from 'react';
import {StyleSheet} from 'react-native';
import {
  FormV,
  defaultValuesDS,
  SENDER_ID as FORMV_SENDER_ID,
} from 'react-form-rs';
import {useViewport} from 'react-viewport-provider';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {DataSet, DataView} from 'vis-data';
import BP from './config/default-blueprint.json';

/**
 * Dataset and DataView
 */
export const SENDER_ID = 'Registration:RegistationPage:OrganizationProfile';
export const orgProfileDS = new DataSet({
  fieldId: 'key',
});
export const KEY = 'accountProfile';
export const orgProfileDV = new DataView(orgProfileDS);
orgProfileDS.add([
  {
    key: KEY,
  },
]);

export type IProps = {
  validate?: boolean;
  onValidateResult?(
    formContext: any,
    status: 'success' | 'error',
    validateResult: any,
  ): void;
};

export const OrganisationProfile = (props: IProps) => {
  const parentRef = React.useRef(() => {});
  const {height} = useViewport();
  // React.useEffect(() => {
  //     if (props.validate) {
  //         parentRef.current && parentRef.current();
  //     }
  // });
  const styles = StyleSheet.create({
    formContainer: {
      width: '100%',
      // height: 0.55 * height,
      marginLeft: 30,
      marginTop: 10,
      backgroundColor: '#ffffff',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
      // overflow: 'scroll',
    },
    container: {},
  });
  return (
    <FBGridV justify={'start'} align={'middle'}>
      <FBItemV colspan={20}>
        <FormV
          components={BP.formFields}
          formContext={{}}
          containerStyle={styles.formContainer}
          validateForm={props.validate}
          onValidateResult={props.onValidateResult}
          defaultValues={defaultValuesDS.get()}
          ref={parentRef}
        />
      </FBItemV>
    </FBGridV>
  );
};
