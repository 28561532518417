import React from 'react';
import RichTextEditor from 'react-rte';
import ComponentHeader from './component-header';
import ComponentLabel from './component-label';


class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(),
    }
  }

  onChange = (value) => {
    console.log('onchange value', value.toString('html'));
    this.setState({value});
    // if (this.props.onChange) {
    //   console.log(`entered`)
    //   // Send the changes up to the parent component as an HTML string.
    //   // This is here to demonstrate using `.toString()` but in a real app it
    //   // would be better to avoid generating a string on each change.
    //   // this.setState({value: value.toString('html')})
    //   // this.props.onChange(
    //   //   value.toString('html')
    //   // );
    // }
  };

  render() {
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <RichTextEditor 
            value={this.state.value} 
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  };
}

export default TextEditor;