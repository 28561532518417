import React from 'react';

import {useViewport} from 'react-viewport-provider';

import {FormListLG} from './FormList.lg';
import {FormListMD} from './FormList.md';
import {FormListSM} from './FormList.sm';


export const FormList = (props: any) => {
    const {bp} = useViewport();

    if(bp === 'xs' || bp === 'sm'){
        return <FormListSM {...props} />
    } else if(bp === 'md'){
        return <FormListMD {...props} />
    } else 
    
        return <FormListLG {...props} />
    
}