import React from 'react';
import { ResetPasswordPage } from './components';

export const ResetPassword = () => {

    const _renderBody = () => {
        return (
            <>
                <ResetPasswordPage />
            </>
        )
    }

    return (
        <>
            {_renderBody()}
        </>
    )
}