import { ApolloProvider } from '@apollo/react-hooks';
import { BindingScope } from '@loopback/context';
import React, { useContext, useEffect, useState } from 'react';
import { StatusBar } from 'react-native';
import 'react-native-gesture-handler';
import 'react-form-builder2/dist/app.css';
import { AppNavigator } from './AppNavigator';
import config from './config/default.config.json';
import { ThemeContext, ThemeName, ThemeProvider } from './providers/theme';
import { GraphqlServer } from './servers/Graphqlserver';
import { ViewportProvider } from 'react-viewport-provider';
import { LOGO_STATIC_URI } from './services/loadAssets';
import { LoadAssetsV } from 'react-native-load-assets-vfw';
import { VlinderApp } from './services/vlinder-app.service';
import { HttpServer } from './servers/HttpServer';
import { AuthService } from './services/auth-service/auth.service';

export interface PackageInfo {
  name: string;
  version: string;
  description: string;
}

export const pkg: PackageInfo = require('../package.json');
export const EVENT_BUS = 'app.eventbus';
export const CONTEXT_BUFFER = 'app.ctx.buffer';
export const GRAPHQL_CLIENT = 'app.servers.graphql.client';
export const MQTT_CLIENT = 'app.servers.mqtt.client';
export const HTTP_CLIENT = 'app.servers.http.client';
export const AUTH_TOKEN = 'app.servers.http.auth.token';

export const app = new VlinderApp();
export const ctx = VlinderApp.ctx;
export let authService: any;

//-----------------------------------------------------------------
const fonts = {
  'SFProText-Bold': require('../assets/fonts/SF-Pro-Text-Bold.otf'),
  'SFProText-Semibold': require('../assets/fonts/SF-Pro-Text-Semibold.otf'),
  'SFProText-Regular': require('../assets/fonts/SF-Pro-Text-Regular.otf'),
  'itce-dscr': require('../assets/fonts/itcedscr.ttf'),
  'times-regular': require('../assets/fonts/times.ttf'),
};
//Load all images here before app launches

const assets: number[] = [LOGO_STATIC_URI];

//-----------------------------------------------------------------
const initialize = async () => { };

const _setUpBindings = () => {
  //app.service(AuthService);
  authService = AuthService.getInstance();

  //Set up services / Extension point
};

const _setUpServers = async () => {
  const graphQLServer = new GraphqlServer(config.servers.graphql.uri);
  if (graphQLServer) {
    ctx.bind(GRAPHQL_CLIENT).to(graphQLServer.client);
  }
  // const mqttServer = new MqttServer(
  //   config.servers.mqtt.uri,
  //   config.servers.mqtt.clientId,
  //   config.servers.mqtt.options,
  // );
  // if (mqttServer) {
  //   ctx.bind(MQTT_CLIENT).to(mqttServer);
  // }
  const httpServer = new HttpServer();
  if (httpServer) {
    //Set Http Server Header here
    app.bind(HTTP_CLIENT).to(httpServer);
  }
};

_setUpBindings();
_setUpServers();

//-----------------------------------------------------------------
const AppV = () => {
  const theme = useContext(ThemeContext);
  useEffect(() => {
    const themeProvider = ThemeProvider.getInstance();
    themeProvider.switchTheme(ThemeName.Vlinder);
  }, []);
  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle={theme?.palette.barStyle}
      />
      <AppNavigator />
    </>
  );
};

const _bootup = async () => {
  /**
   * Ring Level handler
   */
  const authService = app.getSync(
    'services.AuthService',
  ) as AuthService;
  await authService.init();
}

const handleRingLevelUp = async () => {
  await _setUpBindings();
  await _bootup();
  //Any initialisation call to network with backend should happen here
};

export const App = (): JSX.Element => {
  useEffect(() => {
    initialize();
  }, []);
  return (
    <ThemeProvider>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css" />
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
      <ViewportProvider>
        <LoadAssetsV {...{ assets, fonts }}>
          <AppV />
        </LoadAssetsV>
      </ViewportProvider>
    </ThemeProvider>
  );
};
