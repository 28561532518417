import gql from 'graphql-tag';

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($credential: verifyEmailInput) {
    verifyEmail(credential: $credential) {
      userStatus
      email
      features
      userTermsAndConditions{
        data{
          heading
          p
          list{title, sublist{p}}
        }
      }
    }
  }
`;


export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($credential: registerUserInput) {
    registerUser(credential: $credential) {
      email
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrg($data: OrganizationEditMeInput) {
    editMyOrganization(data: $data) {
      name
      email
    }
  }
`;

export const AUTO_LOGIN_USER = gql`
  mutation LoginUser($credential: signInInput) {
    signIn(credential: $credential) {
      accessToken
      features
      refreshToken
      fullName
      userStatus
    }
  }
`;
