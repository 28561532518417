import React from 'react';

import {useViewport} from 'react-viewport-provider';

import {FormScreenLG} from './FormScreen.lg';
import {FormScreenMD} from './FormScreen.md';
import {FormScreenSM} from './FormScreen.sm';


export const FormScreen = (props: any) => {
    const {bp} = useViewport();

    if(bp === 'xs' || bp === 'sm'){
        return <FormScreenSM {...props} />
    } else if(bp === 'md'){
        return <FormScreenMD {...props} />
    } else 
    
        return <FormScreenLG {...props} />
    
}