import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { LoginPageLG } from './LoginPage.lg';

export interface IProps {
  onLogin?(email?: string, password?: string, rememberMe?: boolean): void;
}

export const LoginPage = (props: IProps) => {
  const { bp } = useViewport();

  // if (bp === 'xs' || bp === 'sm') {
  //   return <div />;
  // }

  // if (bp === 'md') {
  //   return <div />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <LoginPageLG {...props} />;
};
