import React, {useContext, useState, useEffect} from 'react';
import {ThemeContext} from '../../../../providers/theme';
import blueprint from '../../config/default-blueprint.json';
import {useViewport} from 'react-viewport-provider';
import {RegistrationPageV} from 'react-registration-template-1';
import {AccountProfile} from './components/AccountProfile';
import {OrganisationProfile} from './components/OrganisationProfile';
import {WalletRegistration} from './components/WalletRegistration';

import {IProps} from './index';

export const RegistrationPageSM = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const {width, height} = useViewport();

  const renderContent = (key: string) => {
    if (key === 'accountProfile') {
      return <AccountProfile />;
    }
    if (key === 'organisationProfile') {
      return <OrganisationProfile />;
    }
    if (key === 'walletRegistration') {
      return <WalletRegistration />;
    }
  };
  return (
    <RegistrationPageV
      containerStyle={{width: 1 * width, height: 1 * height}}
      title={props.title}
      leftAvatar={props.leftAvatar}
      items={props.items ?? []}
      onHeaderLogoPress={props.onHeaderLogoPress}
      waveColor={theme?.palette.secondary}
      renderStepperContent={renderContent}
      stepperWidth={0.7 * width}
      headerTitleToDisplay={props.headerTitleToDisplay}
    />
  );
};
