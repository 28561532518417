import React from 'react';
import { View } from 'react-native';
import { FormGeneratorV, HttpServer } from '../../../../../../widgets/FormBuildGenV/src';
import config from '../../../../../../config/default.config.json';

const http = new HttpServer();

type FormPreviewProps = {
    formId: string;
};

export const FormPreviewLG = ({
    formId
}: FormPreviewProps) => {
    const [data, setData] = React.useState<any>();

    const getUrl = (url: string, cid: string) => {
        return `${url}?cid=${cid}`
    };

    React.useEffect(() => {
        const URL = getUrl(config.servers.http.formEP, formId);

        http.get(URL)
            .then((res: any) => {
                console.log("get Preview res", res)
                if (res?.data?.formsuite) {
                    setData(res?.data?.formsuite?.form?.task_data);
                }
            })
    }, [])
    console.log("data", data)
    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 30
            }}
        >
            {formId && formId !== 'null' && data &&
                <FormGeneratorV
                    //  back_action="/"
                    hide_actions={false}
                    //  back_name="Back"
                    action_name="Test"
                    form_action="/"
                    form_method="POST"
                    onSubmit={(data) => { console.log(`formData after submit`, data)}}
                    data={data}
                    containerStyle={{ width: 500, borderWidth: 2, borderRadius: 10, borderColor: '#d2d3d3', paddingLeft: 40, paddingRight: 40, paddingBottom: 60, boxShadow: "1px 3px 1px #d2d3d3" }}
                    submitButtonContainerStyle={{ borderRadius: 6, backgroundColor: '#F05776', marginTop: 30 }}
                    submitButtonTextStyle={{ color: '#fff', textAlign: 'center', alignSelf: 'center' }}
                />
            }
        </View>
    );
}
