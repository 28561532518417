import React, {useContext, useState} from 'react';
import {View, Text} from 'react-native';
import {StyleSheet} from 'react-native';
import config from '../../../../config/default-blueprint.json'

import {ThemeContext} from '../../../../../../providers/theme';
import {BodyTemplateV} from 'react-body-template-1';
import {InputV} from 'react-input-rs';
import {
  FormBuilderV,
  convertCredentialToSDR,
} from '../../../../../../widgets/FormBuildGenV/src';

const humanizeString = require('humanize-string');
const BP = config.FormBuilder;
const BP2 = config.dashboard;
type FormScreenProps = {
  onSearchClick?(): void;
  onCredentialClick?(data?: any): void;
  onPreviewClick?(eventKey?: string): void;
  breadCrumbsClick?(key: string): void;
  handleInputChange?(value: string): void;
  handleEnterPress?(value: string): void;
  formId?: any;
  token?: string;
  url?: any;
  defaultValue?: string;
  placeholder?: string;
  title?: string;
};

export const FormScreenLG = (props: FormScreenProps) => {
  const {placeholder, title} = props;
  console.log('credential List', BP?.list);
  const theme = useContext(ThemeContext);
  const [userActionKey, setUserActionKey] = React.useState('');
  const onNavItemClick = (eventKey: string) => {
    props?.onPreviewClick && props?.onPreviewClick(eventKey);
    setUserActionKey(eventKey);
  };

  const bodyContent = () => {
    return (
      <View style={StyleSheet.flatten([styles.bodyContentStyle])}>
        <div style={StyleSheet.flatten([styles.formStyle])}>
          <InputV
            onChange={props?.handleInputChange}
            onEnterPress={props?.handleEnterPress}
            placeholder={placeholder}
            title={title}
            defaultValue={props?.defaultValue}
            disabled
          />

          {!!props?.formId && props?.formId !== 'null' && props?.token && (
            <FormBuilderV
              url={props?.url}
              label={props?.formId}
              access_token={props?.token}
              formId={props?.formId}
            />
          )}
        </div>
      </View>
    );
  };

  const onBreadcrumbsClick = (key: string) => {
    props.breadCrumbsClick && props.breadCrumbsClick(key);
  };
  return (
    <>
      <BodyTemplateV
        primaryColor={BP?.primaryColor || theme?.palette.primary}
        primaryHighlightColor={
          BP?.primaryHighlightColor || theme?.palette.secondary
        }
        secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
        ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
        renderContent={bodyContent}
        contentScrollableInline={true}
        headerTitleStyle={{marginLeft: 20}}
        navbarStyle={{}}
        layout={'sidebar-header-content-footer'}
        headerBackgroundColor={'#fff'}
        onNavItemSelect={onNavItemClick}
        breadcrumbs={BP?.breadcrumbs}
        onBreadcrumbsClick={key => onBreadcrumbsClick(key)}
        renderFooter={() => <div />}
        navBarRightItems={BP?.previewButton.navRightItems}
      />
    </>
  );
};

const styles = StyleSheet.create({
  headerTitleStyle: {
    marginLeft: 20,
  },
  bodyContentStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  formStyle:{
    width: '60%', 
    marginBottom: 20
  }
});

FormScreenLG.defaultProps = {
  placeholder: BP2?.inputFormField.placeholder,
  title: BP2?.inputFormField.label,
} as Partial<FormScreenProps>;
