import React, { useContext, useLayoutEffect, useEffect } from 'react';
import { View, Text } from 'react-native';
import { StyleSheet } from 'react-native';

import config from '../../../../config/default-blueprint.json'
import { ThemeContext } from '../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { TableV, TableHeaderDataProps } from 'react-table-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { AvatarV, AvatarSize } from 'react-native-avatar-vfw';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.FormBuilder;

type FormListProps = {
    onSearchClick?(): void;
    onCredentialClick?(data?: any): void;
    onCreateNewBundle?(eventKey?:string):void;
    tableData?: any;
    userType?: string;
    activeCategory?:string;
    findTemplates?:boolean;
    breadCrumbsClick?(key:string):void;
    navBarRightItems?:any;
    loader?: boolean;
};


const generateFakeData = async (schema, rows = 1) => {
    console.log('schema', schema);
    
    let res = [];
    try {
        if (schema) {
            for (let i = 0; i < rows; ++i) {
                let _sample = { ...schema[i] };
                let logoIndex = Array.isArray(_sample?.dataProperties) && _sample?.dataProperties.findIndex(item=>item.key === 'logo');
                // console.log('logo Index in table', logoIndex);
                _sample.credential = (
                    <View style={StyleSheet.flatten([styles.twoColStyle])}>
                        <AvatarV
                            source={{uri:_sample?.icon  ?? `https://ui-avatars.com/api/?name=${_sample.name}`}}
                            avatarStyle={StyleSheet.flatten([styles.iconStyle])}
                            rounded
                            size={AvatarSize.Small}
                        />
                        <Text style={{paddingLeft:5}}>{_sample.name}</Text>
                    </View>
                );
                // _sample.issuer = (
                //     <View>
                //         <Text>{_sample.issuer}</Text>
                //         <Text>
                //             <a href={`mailto:${_sample.email}`}>{_sample.email}</a>
                //         </Text>
                //     </View>
                // );
                // _sample.verified = (
                //     <View style={StyleSheet.flatten([styles.twoColStyle])}>
                //         <IconV icons={BP?.verifiedIcon} />
                //         <Text>{_sample.verified}</Text>
                //     </View>
                // );
                //_sample.action = <IconButtonV icon={{ name: 'more' }} circle />;
                res.push(_sample);
            }
        }
    } catch (err) {
        console.log('error generating data', err);
    }
    return res;
};

const columns: TableHeaderDataProps[] = [
    {
        label: 'Credential Name',
        dataKey: 'credential',
        appearance: 'icon',
        columnProps: {
            fixed: true,
            width: 250,
        },
        labelStyle: {
            color: '#606161',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'Template ID',
        dataKey: 'templateKey',
        appearance: 'text',
        columnProps: {
            fixed: true,
            width: 300,
        },
        labelStyle: {
            color: '#606161',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'Description',
        dataKey: 'group',
        appearance: 'text',
        appearanceProps: {
            style: {
                // color: '#d3d3d3'
            },
        },
        columnProps: {
            sortable: true,
            width: 300,
        },
        cellStyle: {
            //  backgroundColor: '#d3d3d3'
        },
        labelStyle: {
            color: '#606161',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'Action',
        dataKey: 'action',
        appearance: 'button',
        appearanceProps: {
          color: 'black',
          appearance: 'ghost',
          disabled: true,
          buttonStyle: {
            color: '#fff',
          },
          buttonTitleStyle: {
            color: '#fff',
          },
          containerStyle: {
            width: 100,
          },
        },
        columnProps: {
          sortable: false,
          width: 200,
        },
        labelStyle: {
          color: '#606161',
          textTransform: 'uppercase',
        },
        headerCellStyle: {
          backgroundColor: '#f5f5f5',
          height: 50,
        },
      },
];

export const FormListSM = (props: FormListProps) => {

    const {navBarRightItems} =props;
    console.log('credential List', props?.tableData);

    
    const theme = useContext(ThemeContext);
    const [data, setData] = React.useState<any>([]);
    const [userActionKey, setUserActionKey] = React.useState('');
    const [show, setShow] = React.useState<Boolean>(false);
 
    React.useEffect(() => {
        let schema = props?.tableData && Array.isArray(props?.tableData) && props?.tableData.map(item=>{
            return{
                key:item?.templateKey,
                templateKey:item?.templateKey,
                name:item?.name,
                group:item?.description,
                action: {
                    title: 'More',
                    disabled: false,
                  },
            }
        });
        console.log('list', schema);
        schema && generateFakeData(schema, schema.length).then(res => {
            console.log('result', res);
            res && Array.isArray(res) && setData([...res]);
        });
    }, [props?.tableData]);


    const onNavItemClick = (eventKey: string) => {
        props?.onCreateNewBundle && props?.onCreateNewBundle(eventKey);
        setUserActionKey(eventKey);
     
      };

 
    const onMoreClick = data => {
        console.log('inside table', data);
        if(props.userType === "indivisual"){
            setShow(true)
        }
        else {
            props.onCredentialClick && props.onCredentialClick(data);
        }
        
    };

    const bodyContent = () => {
        if(props?.loader){
            return <LogoSpinnerV imageStyle={{left:'50%'}}/>
        }
        else {
            return (
                <div style={StyleSheet.flatten([styles.tableStyle])}>
                    <TableV
                        //onRowClick={data => {}}
                        onPress={(data: any) => {onMoreClick(data)}}
                        table={{
                            height: 530,
                            headerHeight: 40,
                            rowHeight: 50,
                            wordWrap: true,
                            //cellBordered: true,
                            loading:true,
                            data: [...data],
                        }}
                        columns={columns}
                        
                    />
                </div>
            );
        }
       
    };
  
    const onBreadcrumbsClick=(key:string)=>{
        props.breadCrumbsClick && props.breadCrumbsClick(key)
    }
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                headerBackgroundColor={'#fff'}
                onNavItemSelect={onNavItemClick}
                breadcrumbs={BP?.breadcrumbs}
                onBreadcrumbsClick={(key)=>onBreadcrumbsClick(key)}
                renderFooter={() => <div />}
                navBarRightItems={navBarRightItems}
            />
    
        </>
    );
};

const styles = StyleSheet.create({
    leftcontainerStyle: {
        display: 'flex',
    },
    rightContainerStyle: {
        float: 'right',
    },
    twoColStyle: {
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableStyle: {
       width:"100%"
    },
    modalStyle: {
        width: '60%',
        alignSelf: 'center',
        alignItems: 'center',
        margin: '4% auto 0 auto'
    },
    titleStyle:{
        fontSize:22
    }
});

FormListSM.defaultProps={
    navBarRightItems:BP?.navRightItems
} as Partial<FormListProps>