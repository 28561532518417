import React, { useContext, useState } from 'react';
import { ResetPasswordV } from 'react-reset-password';
import { ThemeContext } from '../../../providers/theme';
import { EventBus } from 'event-bus-vfs';
import { FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import blueprint from '../config/default-bluprint.json';
import Alert from 'rsuite/lib/Alert';
import { IProps } from './index'
const BP = blueprint;
let formContext = {};

export const ResetPasswordLG = (props: IProps) => {
    const [validateForm, setValidateForm] = React.useState<any>(null);
    const theme = useContext(ThemeContext);
    const [date, setDate] = React.useState<Date>(new Date());
    const history = useHistory();
    React.useEffect(() => {
        const app = EventBus.on(FORM_VALUES, value => {
            formContext = { ...value };
        });
        return function () {
            app.off();
        };
    }, []);

    const handleValidateResult = (
        formContext: any,
        status: 'success' | 'error',
        validateResult: any,
    ) => {
        if (status === 'success') {
            //console.log('forgotPassword data collected successful', defaultValuesDS.get('emailAddress')?.defaultValue,);
            props.onResetPassword &&
                props.onResetPassword(
                    defaultValuesDS.get('password')?.defaultValue,
                );
            defaultValuesDS.clear();
        }
        if (status === 'error') {
            BP.forgotPassordErr && Alert.error(BP.forgotPassordErr, 10000);
        }
        setDate(new Date());
        setValidateForm(false);
    };

    const onHeaderLogoPress = () => {
        history.push('/')
    }
    return (
        <>
            <ResetPasswordV
                containerStyle={{ width: '100%', height: '100vh' }}
                title={BP?.title}
                leftAvatar={BP?.leftAvatar}
                resetButtonColor={theme?.palette?.primary}
                bodyTitle={BP?.bodyTitle}
                formFields={BP?.formFields}
                formContext={formContext}
                validateForm={validateForm}
                defaultValues={defaultValuesDS.get()}
                onHeaderLogoPress={onHeaderLogoPress}
                onValidateResult={handleValidateResult}
                onResetPassword={() => {
                    setValidateForm({
                        ...formContext,
                    });
                }}
                waveColor={'rgba(240,87,118,1)'}
                loading={false}
            />
        </>
    )
}